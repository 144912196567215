<template>
    <div class="drawing-board">
        <div class="canvas-board" :style="{ maxHeight: maxHeight }">
            <canvas id="canvas"></canvas>
        </div>
        <el-row class="button-group" align="top">
            <div class="optBar">
            <img class="canRestore" @click="restore" src="../assets/image/canRestore.png" alt="">
            <img class="reDo" @click="clear" src="../assets/image/reDo.png" alt="">
            <!-- <img class="optItem" @click="draw" src="../assets/image/restore.png" alt=""> -->
            <!-- <el-button
                icon="el-icon-edit"
                :type="isDrawing ? 'primary' : 'info'"
                @click="draw"
                >画笔</el-button
            > -->
            <img class="optItem" @click="draw" src="../assets/image/draw.png" alt="">
            <!-- <el-button
                type="success"
                icon="el-icon-check"
                @click="setIcon('true')"
                >正确</el-button
            > -->
            <img class="optItem" @click="setIcon('true')" src="../assets/image/bingo.png" alt="">
            <!-- <el-button
                type="danger"
                icon="el-icon-close"
                @click="setIcon('false')"
                >错误</el-button
            > -->
            <img class="optItem" @click="setIcon('false')" src="../assets/image/wrong.png" alt="">
            <!-- <el-button
                @click="setIcon('question')"
                :style="{
                    backgroundColor: '#f4ea29',
                    borderColor: '#f4ea29',
                    color: '#fff'
                }"
                >？ 疑问</el-button
            > -->
            <img class="optItem" @click="setIcon('question')" src="../assets/image/question.png" alt="">
            <!-- <el-button
                type="warning"
                icon="el-icon-folder-delete"
                @click="clear"
                >清空</el-button
            > -->
            <img class="optItem" @click="clear" src="../assets/image/clear.png" alt="">
            <!-- <el-button type="primary" icon="el-icon-folder" @click="save"
                >保存</el-button
            > -->
            <!-- <img class="optText" @click="addShapeHandle('text')" src="../assets/image/text.png" alt=""> -->
            <div class="optCircle" @click="addShapeHandle('circle')">○</div>
            <div class="optRect" @click="addShapeHandle('rect')">□</div>
            <div class="optText" @click="addShapeHandle('text')">注</div>
            <img class="saveOpt" @click="save" src="../assets/image/save.png" alt="">
            </div>
        </el-row>
    </div>
</template>

<script>
import { fabric } from "fabric";

export default {
    data() {
        return {
            canvas: null,
            // 编辑模式
            isDrawing: false,
            // 对 图标
            trueIcon: null,
            // 错 图标
            falseIcon: null,
            // 问号 图标
            questionIcon: null,
            // 背景
            backgroundImage: null,
            backgroundConfig: null,
            backgroundJson: null,
            // 画板高度
            maxHeight: 0,
            // canvas宽度
            canvasWidth:'',
            // 添加文字配置项
            activeObjectData :{
		     type: 'textbox',
		     text: '点击编辑文字',
		     fontSize: 34,
		     left: 300,
		     top: 300,
		     width: 200,
		     fill: 'red',
		    //  textBackgroundColor: 'rgba(0,0,0,.9)',
		     opacity:1,
		     stroke: '#ffffff',
		     strokeWidth: 0,
		     background: '#7ED321',
		     fontWeight:'normal',
		     fontStyle:'normal',
		     underline:false,
		     linethrough:false,
		     overline:false,
		     textAlign:'left',
		     lineHeight:1,
		     charSpacing:1,
		     fontFamily:'hyzktjjkt',
		     rotate: 0,
		     selectable: true,
	 	    },
             // 添加椭圆配置项
            circleData :{
                    rx:40,// rx > ry 
                    ry:20,// 
                    top:100,
                    left:100,
                    strokeWidth: 3,
                    stroke: 'red',
                    fill:''
	 	    },
            //  添加矩形配置项
            rectData:{   
                // 距离容器顶部
                top:100, 
                // 距离容器左边距
                left:100, 
                // ⾼度
                width:200, 
                // 宽度
                height:50, 
                // 填充的颜⾊
                fill:'', 
                // 边框颜色
                stroke: 'red',
                strokeWidth: 3,
                // 矩形圆角
                // rx:20,
                // 矩形圆角
                // ry:20
             }
        };
    },
    created() {
        this.maxHeight = document.body.clientHeight - 175 + "px";
        this.$nextTick(async () => {
            this.canvasWidth = (document.getElementsByClassName('canvas-board')[0].clientWidth)*0.8
            // console.log(this.canvasWidth);
            /******************************************************************
             *                         选择元素框体样式
             ******************************************************************/
            // 修改选择框样式
            fabric.Object.prototype.set({
                transparentCorners: false,
                borderColor: "#409eff",
                cornerColor: "#409eff",
                cornerSize: 8
            });

            /******************************************************************
             *                         元素删除的功能
             ******************************************************************/
            // 删除功能
            let deleteImg = document.createElement("img");
            deleteImg.setAttribute("crossOrigin", "anonymous");
            deleteImg.src =
                "https://moolsvideo.oss-cn-beijing.aliyuncs.com/report/public/delete.svg";

            // 删除的控制器
            fabric.Object.prototype.controls.deleteControl = new fabric.Control(
                {
                    x: 0.5,
                    y: -0.5,
                    offsetY: -16,
                    offsetX: 16,
                    cursorStyle: "pointer",
                    // 删除的逻辑
                    mouseUpHandler: (eventData, transform) => {
                        let target = transform.target;
                        let canvas = target.canvas;
                        if (target._objects) {
                            // 删除组
                            target._objects.forEach(item =>
                                canvas.remove(item)
                            );
                        } else {
                            canvas.remove(target);
                        }
                        canvas.requestRenderAll();
                    },
                    // 渲染器
                    render: function renderIcon(
                        context,
                        left,
                        top,
                        styleOverride,
                        fabricObject
                    ) {
                        let size = this.cornerSize;
                        context.save();
                        context.translate(left, top);
                        context.rotate(
                            fabric.util.degreesToRadians(fabricObject.angle)
                        );
                        context.drawImage(
                            deleteImg,
                            -size / 2,
                            -size / 2,
                            size,
                            size
                        );
                        context.restore();
                    },
                    cornerSize: 24
                }
            );

            /******************************************************************
             *                              加载对号
             ******************************************************************/
            let trueIcon = document.createElement("img");
            trueIcon.setAttribute("crossOrigin", "anonymous");
            trueIcon.src =
                "https://moolsvideo.oss-cn-beijing.aliyuncs.com/report/public/true.svg";
            trueIcon.onload = await (() => {
                this.trueIcon = trueIcon;
            })();

            /******************************************************************
             *                              加载叉
             ******************************************************************/
            let falseIcon = document.createElement("img");
            falseIcon.setAttribute("crossOrigin", "anonymous");
            falseIcon.src =
                "https://moolsvideo.oss-cn-beijing.aliyuncs.com/report/public/false.svg";
            falseIcon.onload = await (() => {
                this.falseIcon = falseIcon;
            })();

            /******************************************************************
             *                            加载问号
             ******************************************************************/
            let questionIcon = document.createElement("img");
            questionIcon.setAttribute("crossOrigin", "anonymous");
            questionIcon.src =
                "https://moolsvideo.oss-cn-beijing.aliyuncs.com/report/public/question.svg";
            questionIcon.onload = await (() => {
                this.questionIcon = questionIcon;
            })();

            this.canvas = new fabric.Canvas("canvas");
            // 修改背景颜色
            this.canvas.backgroundColor = "#FFFFFF";
            // 修改画笔颜色
            this.canvas.freeDrawingBrush.color = "red";
            // 修改画笔宽度
            this.canvas.freeDrawingBrush.width = "4";
            // console.log(this.canvasWidth);
            this.canvas.setWidth(this.canvasWidth);

            this.$emit("ready");
        });
    },
    methods: {
        /**
         * @name: 撤销上一步操作
         * @author: LXY
         * @date: 20220617
         */ 
        restore()
        {
            // console.log(this.canvas._objects);
            if(this.canvas._objects.length>1)
            {
                this.canRestore = true
                this.canvas._objects.pop();
                this.canvas.renderAll()
            }
            else
            {
                this.canRestore = false
            }

        },
        draw() {
            // 更改画笔状态
            this.isDrawing = !this.isDrawing;
            this.canvas.isDrawingMode = this.isDrawing;
            // 修改画笔颜色
            this.canvas.freeDrawingBrush.color = "red";
            // 修改画笔宽度
            this.canvas.freeDrawingBrush.width = 4;
        },
        /**
         * @name: 教师批注文字，画圈，画方框
         * @desc: 描述
         * @author: LXY
         * @date: 20220617
         */                                       
        addShapeHandle(sign)
        {
            if(sign == 'text')
            {
                let Shape = new fabric.Textbox(this.activeObjectData.text || '', this.activeObjectData);
                this.canvas.add(Shape).setActiveObject(Shape);
            }
            else if(sign == 'circle')
            {
                let Shape = new fabric.Ellipse(this.circleData);
                this.canvas.add(Shape).setActiveObject(Shape);
            }
            else if(sign == 'rect')
            {
                let Shape = new fabric.Rect(this.rectData);
                this.canvas.add(Shape).setActiveObject(Shape);
            }
        },
        clear() {
            this.canvas.clear();
            if (this.backgroundImage) {
                this.init(this.backgroundImage, this.backgroundConfig);
            } else if (this.backgroundJson) {
                this.backgroundJson.objects = [this.backgroundJson.objects[0]];
                this.initFromJson(this.backgroundJson);
            } else {
                this.destroy();
            }
        },
        setIcon(key) {
            let icon = {
                true: this.trueIcon,
                false: this.falseIcon,
                question: this.questionIcon
            }[key];
            this.drawImage(icon, {
                left: 100,
                top: 50
            });
        },
        drawImage(image, config) {
            let imgInstance = new fabric.Image(image, config);
            this.canvas.add(imgInstance);
        },
        init(image, config = {}) {
            this.backgroundImage = image;
            this.backgroundConfig = config;
            this.backgroundJson = null;
            let tempConfig = { selectable: false };
            if (config.scale) {
                tempConfig.scaleX = tempConfig.scaleY = config.scale;
            }
            if (config.height < 100) config.height = 100;
            if (config.refresh) this.destroy();
            this.canvas.setHeight(config.height);
            this.drawImage(this.backgroundImage, tempConfig);
        },
        initFromJson(json, config = {}) {
            this.$nextTick(() => {
                this.backgroundImage = null;
                this.backgroundJson = json;
                if (config.refresh) this.destroy();
                this.backgroundJson.objects[0].selectable = false;
                let height =
                    this.backgroundJson.objects[0].height *
                    this.backgroundJson.objects[0].scaleY;
                if (height < 100) height = 100;
                this.canvas.setHeight(height);
                this.canvas.loadFromJSON(this.backgroundJson);
            });
        },
        save() {
            this.$emit(
                "save",
                this.canvas.toDatalessJSON(),
                this.canvas.toDataURL()
            );
        },
        // 一个假的销毁
        destroy() {
            this.canvas.clear();
            // 修改背景颜色
            this.canvas.backgroundColor = "#FFFFFF";
        }
    }
};
</script>

<style lang="scss" scoped>
.drawing-board {
    position: relative;
    width: 100%;
    height: 100%;
    .canvas-board {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }
}
.optBar{
    text-align: center;
    width: 80%;
    margin: 1.6667vw auto 0;
}
.optCircle,.optRect{
    color: #0D5570;
    cursor: pointer;
    display: inline-block;
    // width: 1.5rem;
    font-size: 2.5vw;
    margin: 0 1.25vw;
    line-height: 0.8333vw;
    // transform: translateY(-0.25rem);

}
.reDo{
    cursor: pointer;
    float: left;
    width: 2.9167vw;
    margin: 0 1.25vw;

}
.canRestore{
    cursor: pointer;
    float: left;
    width: 2.9167vw;
    margin-right: 1.25vw;
}
.optItem{
    cursor: pointer;
    display: inline-block;
    width: 4.4792vw;
    margin: 0 1.25vw;
}
.saveOpt{
    cursor: pointer;
    float: right;
    width: 6.1458vw;
}
.optText{
    color: #0D5570;
    cursor: pointer;
    display: inline-block;
    // width: 1.5rem;
    font-size: 1.3333vw;
    margin: 0 1.25vw;
    transform: translateY(-0.2083vw);
}
canvas {
    border: 1px solid #eaeefb;
    margin: 0 auto !important;
}

.button-group {
    // position: absolute;
    // width: 100px;
    // right: 20px;
    // top: 0px;

    .el-button {
        margin-left: 0px;
        margin-bottom: 10px;
    }
}
</style>
