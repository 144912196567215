import { request } from "@/request/http";

export default {
    changeReportStatus: (data) => {
        return request("POST", `index.php/report/changeReportStatus`, data);
    },

    correctReport: (data) => {
        return request("POST", `index.php/report/correctReport`, data);
    },

    reReviewReport: (data) => {
        return request("POST", `index.php/report/reReviewReport`, data);
    },

    getReportContent: (data) => {
        return request("GET", `index.php/report/getReportContent`, data);
    },
};