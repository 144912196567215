import { Affix } from "vue-affix";
import VueUeditorWrap from "vue-ueditor-wrap";
import DrawingBoard from "@/components/DrawingBoard";
import domtoimage from "dom-to-image";
import API from "@/api/teacher/report/correction";
import { get, post, upload } from "@/request/http";
import { startLoading, endLoading, sleep, dateFormat } from "@/utils/function";
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";

export default {
  components: {
    Affix,
    VueUeditorWrap,
    DrawingBoard,
  },
  inject: ['reload'],
  data() {
    return {
      reportscoreTotal:0,
      // 报告退回原因
      remark:'',
      nameTitle: '',
      havename: true,
      // 退回报告理由
      returnReportReason: '',
      // 退回报告弹窗
      returnReportDialog: false,
      // 是否还有报告
      haveReport: true,
      // 报告补交(0-正常，1-补交)
      is_make_up: "0",
      // 用户信息
      userInfo: {},
      // 提交时间
      addtime: '',
      // 学生姓名
      student_name: '',
      // 学生学号
      personal_id: '',
      // 报告各项分数
      reportScore: {},
      // 报告ID
      id: 0,
      // 项目ID
      projectId: 0,
      // 用户ID
      userId: 0,
      // 群组ID
      groupId: 0,
      // 下一个
      next: null,
      // 报告内容
      contentList: [],
      // 教师批改记录
      correctionList: [],
      // 批语
      reply: "",
      // 总分
      total: 0,
      // 已分配总分
      assignedTotal: 0,
      // 报告状态
      status: 0,
      // 弹出层
      dialogVisible: false,
      // 画板宽度
      imageWidth: '',
      // 画板状态
      isCanvasReady: false,
      // 正在被编辑的ID
      drawing: null,
      // 虚拟表格数据
      tableData: [],
      // 开放单元id（从那个开放单元跳转到这个页面的）
      open_id: 0,
      // pdf 链接
      pdf_path: '',
      // 附件信息
      fileinfo: [],
      // 凡是要复制的样式，都写在这
      CSSRules: ["color", "border", "width", "margin-right"],
      // 总得分
      totalScore: 0,
      // 项目id
      project_id: ''
    };
  },
  created() {
    this.id = this.$route.query.report_id;
    this.nameTitle = this.$route.query.name;
    this.open_id = this.$route.query.open_id;
    this.project_id = this.$route.query.project_id;
    this.groupId = this.$route.query.group_id;
    this.student_name = this.$route.query.student_name;
    this.personal_id = this.$route.query.personal_id;
    this.addtime = this.$route.query.addtime;
    this.getContent();
  },
  methods: {
    /**
      * @name: 返回上一级
      * @author: camellia
      * @date: 2021-01-15 14:00:30
      */
    goBack() {
      // this.$router.back()
      let self = this;
      self.$router.push({
        path: "/teacher/studentslist",
        query: {
          id: self.open_id,
          name: self.$route.query.name,
          hideMenu: 1
        }
      }).then((res) => {
        self.reload()
        // console.log(res);
      })
    },
    /**
     * @name: 关闭批注对话框
     * @author: camellia
     * @date: 2022-06-10
     */
    correctClose() {
      let self = this;
      self.dialogVisible = false;
      // endLoading(startLoading())
    },
    /**
     * @name: 非递归、深度优先遍历
     * @author: camellia
     * @date: 2022-06-10
     */
    DFSDomTraversal(root) {
      if (!root) {
        return;
      }
      const arr = [];
      const queue = [root];
      let node = queue.shift();

      while (node) {
        arr.push(node);
        if (node.children.length) {
          for (let i = node.children.length - 1; i >= 0; i--) {
            queue.unshift(node.children[i]);
          }
        }
        node = queue.shift();
      }
      return arr;
    },

    /**
     * @name: 复制样式
     * @author: camellia
     * @date: 2022-06-11
     * @param:	element	type	dom对象
     */
    copyStyle(element) {
      var self = this;
      const styles = getComputedStyle(element);

      self.CSSRules.forEach(rule => {
        element.style.setProperty(rule, styles.getPropertyValue(rule));
      });
    },

    /**
     * @name: 图片转base64
     * @author: camellia
     * @date: 2022-06-11
     * @param:	element	type	dom对象
     */
    img2base64(element) {
      return new Promise((resolve, reject) => {
        const img = new Image();

        // 处理 canvas 受污染的情况
        img.crossOrigin = "anonymous";

        img.onerror = reject;
        img.onload = function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          // console.log(this.naturalWidth,this.naturalHeight);
          canvas.width = this.naturalWidth;
          canvas.height = this.naturalHeight;
          ctx.drawImage(this, 0, 0);
          resolve(canvas.toDataURL());
        };
        img.src = element.src;
      });
    },

    /**
     * @name: dom 绘制 canvas
     * @author: camellia
     * @date: 2022-06-11
     * @param:	data	type	description
     */
    async dom2base64(root, dpr = window.devicePixelRatio) {
      // 判断设备类型，平板电脑宽度小
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.imageWidth = (document.getElementsByClassName('canvas-dialog')[0].clientWidth ? document.getElementsByClassName('canvas-dialog')[0].clientWidth : '1200') * 0.8 - 100;
      }
      else {
        this.imageWidth = (document.getElementsByClassName('canvas-dialog')[0].clientWidth ? document.getElementsByClassName('canvas-dialog')[0].clientWidth : '1920') * 0.8 - 100;
      }
      this.DFSDomTraversal(root).forEach(this.copyStyle);
      const imgElements = [...root.querySelectorAll("img")];
      const base64Result = await Promise.all(imgElements.map(this.img2base64));
      const width = root.offsetWidth;
      const height = root.offsetHeight;
      let XHTML = new XMLSerializer().serializeToString(root);
      imgElements.forEach((element, index) => {
        XHTML = XHTML.replace(element.src, base64Result[index]);
      });
      const SVGDomElement = `<svg xmlns="http://www.w3.org/2000/svg" height="${height}" width="${width}">
                                  <foreignObject height="100%" width="100%">${XHTML}</foreignObject>
                              </svg>`;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = width * dpr;
      canvas.height = height * dpr;
      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;
      const img = new Image();
      // img.onload = function() {
      //     ctx.scale(dpr, dpr);
      //     ctx.drawImage(this, 0, 0);
      //     document.body.appendChild(canvas);
      // };
      // img.src = `data:image/svg+xml,${SVGDomElement}`;
      // let img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      // img.src = src;
      img.onload = () => {
        let scale = this.imageWidth / img.width;
        this.$refs.canvas.init(img, {
          scale: scale,
          height: img.height * scale,
          refresh: true,
        });
        // endLoading(startLoading())
        this.$Loading.hide();

      };
      img.src = `data:image/svg+xml,${SVGDomElement}`;
    },
    /**
     * @name: 下载附件
     * @author: camellia
     * @date: 2022-05-13
     * @param:	url	type	附件链接
     */
    downFile(url) {
      window.open(url);
    },
    /**
     * @name:  字符串是否含有html标签的检测
     * @author: camellia
     * @date: 2022-04-01
     * @param htmlStr
     */
    checkHtml(htmlStr) {
      var reg = /<[^>]+>/g;
      return reg.test(htmlStr);
    },
    /**
     * @name: 获取报告内容
     * @author: camellia
     * @date: 2021-11-17
     */
    getContent() {
      var self = this;
      self.assignedTotal = 0
      // 开启loading
      nprogressStart();
      self.$Loading.show();
      let data = {
        id: this.id,
        groupId: this.groupId,
        open_id: this.open_id,
        project_id: this.project_id
      }
      API.getReportContent(data).then((res) => {
        if (res.data.status != 1 && res.data.status != 2) {
          window.history.back();
        }
        // =============================================================================
        // ADD BY  lxy  处理报告评阅结果图片加载失败的问题
        // console.log(res.data.record);
        // res.data.record.forEach(item=>{
        //     if(item.type=='image')
        //     {
        //         var start=item.value.indexOf('"');
        //         var end=item.value.indexOf('"',start+1);
        //        item.value=item.value.substring(start+1,end);
        //     }
        //     if(item.type=='xuni')
        //     {
        //         this.tableData=JSON.parse(item.value)
        //     }
        // })
        // =============================================================================
        this.reportScore = res.score;
        this.contentList = res.data.record;
        this.next = res.next;
        this.userId = res.data.userid;
        this.projectId = res.data.project_id;
        this.reply = res.data.comment;
        this.remark = res.data.remark;
        this.status = res.data.status;
        this.pdf_path = res.data.pdf_path;
        this.fileinfo = res.fileinfo;
        this.userInfo = res.userInfo;
        this.is_make_up = res.data.is_make_up;
        this.addtime = dateFormat('YYYY-mm-dd', new Date(res.data.addtime));
        if (!res.data.teacher_record) {
          res.data.teacher_record = [];
        }
        if (this.contentList) {
          this.contentList.forEach((item, index) => {
            if (item.type == "image") {
              if (self.checkHtml(item.value)) {
                this.contentList[index].ishtml = true;
              } else {
                this.contentList[index].ishtml = false;
              }
            }
            // 计算总分
            this.total += parseInt(item.score);
            item.marksList = [];
            if (item.mark != "undefined" && item.mark != undefined) {
              // console.log(item.mark);
              // 分割标签
              item.marksList = item.mark.split(/;|；|,|，/);
            }
            // 教师批改默认值设定
            if (!res.data.teacher_record[index]) {
              res.data.teacher_record[index] = {};
            }
            this.correctionList.push({
              id: item.id,
              chooseMarksList: res.data.teacher_record[index].channelstr || [],
              reply: res.data.teacher_record[index].beizhu || "",
              score: res.data.teacher_record[index].truescore || null,
              tuya: res.data.teacher_record[index].tuya || null,
              // json: res.data.teacher_record[index].json || null,
            });
            this.assignedTotal +=
              parseInt(res.data.teacher_record[index].truescore) || 0;
          });
        }
        let preview_score = parseFloat(this.reportScore.preview_score) || 0;
        let exam_score = parseFloat(this.reportScore.exam_score) || 0;
        let exp_score = parseFloat(this.reportScore.exp_score) || 0;
        let opt_score = parseFloat(this.reportScore.opt_score) || 0;
        let kkkf = parseFloat(this.reportScore.kkkf) || 0;

        this.totalScore = parseFloat(this.assignedTotal) + preview_score + exam_score + exp_score + opt_score + kkkf;
        let date = res.classTime.substring(0,16)
        let time = res.classTime.substring(33,38)
        let classTime = date + '-' + time
        if (window.sessionStorage.getItem('havename') == '1') {
          self.$router.push({
            path: "/teacher/report/reportCorrection",
            query: {
              report_id: self.id,
              project_id: self.projectId,
              group_id: self.groupId,
              hideMenu: 1,
              open_id: self.open_id,
              name: self.nameTitle + '/ '+classTime + ' ' + res.classdName,
              type: 1
            },
          }).then(() => {
            window.sessionStorage.setItem('havename', 2)
            self.reload()
          })
          self.havename = false
        }
        // 关闭loading
        nprogressClose();
        self.$Loading.hide();
      });
    },
    /**
     * @name: 滑动窗口到指定dom位置
     * @author: camellia
     * @date: 2022-04-01
     */
    scroll(index) {
      document
        .getElementsByName("item")
      [index].scrollIntoView({ behavior: "smooth" });
    },
    /**
     * @name: 给分input修改
     * @author: camellia
     * @date: 2022-04-01
     */
    handleScoreInput(value, max, index) {
      let temp = parseInt(value);
      if (isNaN(temp)) temp = "";
      if (temp > max) {
        this.correctionList[index].score = max;
        
      }
      else if (temp < 0) {
        this.correctionList[index].score = 0;
      }
      else {
        this.correctionList[index].score = temp;
      }
      console.log('总分变了');
      // 计算已分配总分
      // this.reportscoreTotal = this.reportscoreTotal + this.correctionList[index].score;
      this.reportscoreTotal = 0
      console.log(this.correctionList);
      this.correctionList.forEach((item) => {
        if (item.score) {
          this.reportscoreTotal += parseFloat(item.score);
          // console.log(item.score);
        }
      });
      console.log(this.reportscoreTotal);
      // console.log(this.reportscoreTotal);
      this.assignedTotal = this.reportscoreTotal;//*/
      let preview_score = parseFloat(this.reportScore.preview_score) || 0;
      let exam_score = parseFloat(this.reportScore.exam_score) || 0;
      let exp_score = parseFloat(this.reportScore.exp_score) || 0;
      let opt_score = parseFloat(this.reportScore.opt_score) || 0;
      let kkkf = parseFloat(this.reportScore.kkkf) || 0;
      this.totalScore = parseFloat(this.reportscoreTotal) + preview_score + exam_score + exp_score + opt_score + kkkf;
      
    },
    /**
     * @name: mark修改了
     * @author: camellia
     * @date: 2022-04-01
     */
    handleMarksChange(value, index) {
      // this.correctionList[index].mark = value.join(";");
    },

    /**
     * @name:点击批注
     * @author: camellia
     * @date: 2022-04-01
     */
    handleMarkClick(row, index) {
      var self = this;
      // self.markTimes += 1;
      nprogressStart();
      self.$Loading.show();
      sleep(2000).then(res => {
        self.dialogVisible = true;
      })
      // 等画板初始化
      this.waitCanvasInit(() => {
        // 如果现在修改的不是上一次关闭的，把上一次留下的记录销毁
        // 反之啥都不用干
        if (this.drawing !== index) {
          // 修改当前被修改的状态
          this.drawing = index;
          // 如果已经有json了
          if (this.correctionList[index].json) {
            this.$nextTick(() => {
              // self.dialogVisible = true;
              this.$refs.canvas.initFromJson(this.correctionList[index].json);
            });
            nprogressClose();
            self.$Loading.hide();
          }
          else {
            // if (row.type !== "image") 
            // {
            let node = document.getElementById("dom" + index);
            // self.dialogVisible = true;
            // 将dom绘制到canvas上
            this.dom2base64(node)
            // node.style.width = this.imageWidth + "px";
            /*node.style.width = "auto";
            console.log(node);
            setTimeout(() => {
              domtoimage
                .toPng(node, { width: this.imageWidth })
                .then((dataUrl) => {
                  // console.log(3333333333);
                  node.style.width = "auto";
                  this.uploadBase64ToOss(dataUrl, (res) => {
                    console.log(res)
                    this.setCanvasBackground(res.data);
                  });
                });
            }, 500);//*/
            // } 
            // else 
            // {
            //   this.setCanvasBackground(row.value);
            // }
          }
        }
      });
    },
    /**
     * @name: 设置画板背景图（废弃）
     * @author: camellia
     * @date: 2022-01-18
     * @param:	src	string	图片链接
     */
    setCanvasBackground(src) {
      let img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.src = src;
      img.onload = () => {
        let scale = this.imageWidth / img.width;
        this.$refs.canvas.init(img, {
          scale: scale,
          height: img.height * scale,
          refresh: true,
        });
      };
    },
    /**
     * @name: 批注保存（将图片上传OSS）
     * @author: camellia
     * @date: 2022-01-18
     * @param:	src	string	图片链接
     */
    handleCanvasSave(json, image) {
      this.uploadBase64ToOss(image, (res) => {
        // this.correctionList[this.drawing].json = json;
        this.correctionList[this.drawing].tuya = res.data;
        this.dialogVisible = false;
      });
    },
    /**
     * @name:上传base64图片到Oss
     * @author: camellia
     * @date: 2022-04-01
     */
    uploadBase64ToOss(image, callback) {
      // startLoading()
      let self = this;
      nprogressStart();
      self.$Loading.show();
      upload(
        "index.php/report/uploadReportCorrectionImage",
        {
          projectId: this.projectId,
        },
        this.convertBase64UrlToBlob(image),
        ".png"
      ).then((res) => {
        // endLoading(startLoading())
        nprogressClose();
        self.$Loading.hide();
        callback(res);
      })
        .catch(() => {
          // endLoading(startLoading())
          nprogressClose();
          self.$Loading.hide();
        });
    },
    /**
     * @name:base64转blob
     * @author: camellia
     * @date: 2022-04-01
     */
    convertBase64UrlToBlob(image) {
      let split = image.split(",");
      let bytes = window.atob(split[1]);
      let ab = new ArrayBuffer(bytes.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], { type: split[0] });
    },
    /**
     * @name:提交报告批改
     * @author: camellia
     * @date: 2022-04-01
     */
    submit(key) {
      // startLoading();
      let self = this;
      nprogressStart();
      self.$Loading.show();
      let teacherRecord = [];
      this.correctionList.forEach((item) => {
        let record = {};
        record.id = item.id;
        record.truescore = item.score ?? 0;
        record.beizhu = item.reply || "";
        if (item.tuya) record.tuya = item.tuya;
        // if (item.json) record.json = item.json;
        if (item.chooseMarksList.length)
          record.channelstr = item.chooseMarksList;
        teacherRecord.push(record);
      });

      let param = {
        userId: this.userId,
        projectId: this.projectId,
        teacher_record: JSON.stringify(teacherRecord),
        comment: this.reply,
      }
      API.correctReport(param).then((res) => {
        if (res.code == 1) {
          // this.$message.success("操作成功");
          if (key == "next") {
            this.id = this.next;
            if (this.next) {
              this.$router.push({
                path: "/teacher/report/reportCorrection",
                query: {
                  project_id: this.projectId,
                  report_id: this.next,
                  group_id: this.groupId,
                  hideMenu: 1,
                  open_id: this.open_id,
                  name: this.$route.query.name,
                  type: 1
                },
              }).then(() => {
                this.reload()
              })
            }
            else {
              this.haveReport = false
            };
            // location.reload();
          }
          else {
            this.getContent();
            // window.location.assign(window.sessionStorage.getItem('href'))
            // if (this.open_id) {
            //   this.$router.push({
            //     path: "/teacher/studentslist",
            //     query: {
            //       id: this.open_id,
            //       hideMenu: 1
            //     },
            //   });
            // }
            // else {
            //   this.$router.push("/teacher/projectList");
            //   // this.$router.push({
            //   //   path: "/teacher/studentslist",
            //   //   query: {
            //   //     id: this.open_id,
            //   //   },
            //   // });
            // }
            // this.status = 2;
          }
        }
        // 关闭loading
        // endLoading(startLoading());
        nprogressClose();
        self.$Loading.hide();
      });
    },
    /**
     * @name: 等待画板初始化有亿点慢，所以写了这个
     * @author: camellia
     * @date: 2021-11-29
     */
    waitCanvasInit(callback) {
      if (this.isCanvasReady) {
        callback();
      }
      else {
        let wait = setInterval(() => {
          if (this.isCanvasReady) {
            clearInterval(wait);
            callback();
          }
        }, 1);
      }
    },
    /**
     * @name: 展示退回报告弹窗
     * @author: LXY
     * @date: 2021-11-29
     */
    showreturnReportDialog() {
      this.returnReportDialog = true;
    },
    /**
     * @name: 退回报告
     * @author: camellia
     * @date: 2021-11-29
     */
    returnReport() {
      // 开启loading
      // startLoading();
      let self = this;
      nprogressStart();
      self.$Loading.show();
      let data = {
        id: this.id,
        remark: self.returnReportReason
      }
      API.changeReportStatus(data).then((res) => {
        if (res.code == 1) {
          // 缓存上一个进来的页面
          // window.location.assign(window.sessionStorage.getItem('href'))
          this.id = this.next;
          this.returnReportDialog = false
          if (this.next) {
            this.$router.push({
              path: "/teacher/report/reportCorrection",
              query: {
                project_id: this.projectId,
                report_id: this.next,
                group_id: this.groupId,
                hideMenu: 1,
                open_id: this.open_id,
                name: this.$route.query.name,
                type: 1
              },
            }).then(() => {
              this.reload()
            })
          }
          else {
            this.haveReport = false
          }
          this.$Tips({
            // 消息提示内容
            message: res.msg,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: 'success',
            displayTime: 1500
          })
          // this.$message.success("操作成功");
          // if (this.open_id && this.open_id != 'undefined') {
          //   console.log(this.open_id);
          //   this.$router.push({
          //     path: "/teacher/studentslist",
          //     query: {
          //       id: this.open_id,
          //       hideMenu: 1
          //     },
          //   });
          // }
          // else {
          //   this.$router.push("/teacher/projectList");

          // }
        }
        else
        {
          this.$Tips({
            // 消息提示内容
            message: res.msg,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: 'warning',
            // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
            button: [
              {
                type: 1,
                text: '知道了'
              }
            ],
          })
        }
        // 关闭loading
        nprogressClose();
        self.$Loading.hide();
      });
    },
    /**
     * @name:重新评阅
     * @author: camellia
     * @date: 2022-04-01
     */
    reviewReport() {
      // 开启loading
      // startLoading();
      let self = this;
      self.correctionList = [];
      nprogressStart();
      self.$Loading.show();
      let data = {
        project_id: this.projectId,
        userid: this.userId,
      }
      API.reReviewReport(data).then((res) => {
        if (res.code == 1) {
          this.status = 1;
          // this.$message.success("操作成功");
          this.getContent();
        }
        // 关闭loading
        nprogressClose();
        self.$Loading.hide();
      });
    },
    /**
     * @name:查看pdf
     * @author: camellia
     * @date: 2022-04-01
     */
    showPdf() {
      window.open(this.pdf_path);
      /*post("index.php/report/getStudentPdf", {
        userId: this.userId,
      }).then((res) => {
        if (res.code == 1) {
          window.open(
            "http://py.mools.net/static/media/pdf/" +
              this.projectId +
              "/" +
              res.data
          );
        }
      });//*/
    },
  },
};
